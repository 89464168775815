<template>
    <section
        class="blockElement"
        
    >
        <div class="row">
            <div class="col-12 customHeight">
                <div class="rightTradeChart position-relative">
                    <ul id="tabs-nav" class="zuluTabs d-flex align-items-center mb-3">
                        <li :class="[{ 'active': charttab == 1 }]"><a class="pt-3 pb-3 f-16" href="javascript:void(0)" @click="changeChartTab(1)">Performance</a></li>
                        <li :class="[{ 'active': charttab == 2 }]"><a class="pt-3 pb-3 f-16" href="javascript:void(0)" @click="changeChartTab(2)">Trading</a></li>
                        <li :class="[{ 'active': charttab == 3 }]"><a class="pt-3 pb-3 f-16" href="javascript:void(0)" @click="changeChartTab(3)">DrawDown</a></li>
                        <li :class="[{ 'active': charttab == 4 }]"><a class="pt-3 pb-3 f-16" href="javascript:void(0)" @click="changeChartTab(4)">Slippage</a></li>
                    </ul>
                    <div class="position-relative" style="height:300px;">
                        <Loader :classname="'innerLoader'" v-if="store.singleLoading"></Loader>
                        <Performance v-if="charttab == 1" />
                        <Trading v-if="charttab == 2" />
                        <Drawdown v-if="charttab == 3" />
                        <Slippage v-if="charttab == 4" />
                    </div>
                    <div  v-if="charttab != 4">
                        <ul class="d-flex align-items-center justify-content-evenly">
                            <li v-for="list in dateDrop" :key="list.key">
                                <a class="f-14 gray selectDay" @click.prevent="dateDropSelected = list; changeRoute()" :class="[{ 'active': dateDropSelected.value == list.value }]" href="javascript:void(0);">{{ list.value }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import Performance from "@/components/charts/mobile-charts/trader-performance";
    import Trading from "@/components/charts/mobile-charts/trader-trading";
    import Drawdown from "@/components/charts/mobile-charts/trader-drawdown";
    import Slippage from "@/components/charts/mobile-charts/trader-slippage";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                charttab: 1,
                dateDrop: [
                    { key: 1, value: "1D" },
                    { key: 3, value: "3D" },
                    { key: 7, value: "7D" },
                    { key: 30, value: "1M" },
                    { key: 90, value: "3M" },
                    { key: 180, value: "6M" },
                    { key: 365, value: "1Y" },
                    { key: 10000, value: "Overall" },
                ],
                dateDropSelected: { key: 10000, value: "Overall" },
            };
        },
        components: {
            Performance,
            Trading,
            Drawdown,
            Slippage,
        },
        methods: {
            changeChartTab(val) {
                this.charttab = val;
            },
            changeRoute() {
                let path = `/advanced/${this.$route.params.id}`;
                this.$router.replace({ path: path, query: { t: this.dateDropSelected.key } });
            },
            calltraderDetail() {
                this.changeRoute();
                //this.store.calltraderDetail({}, true, this.$route.params.id, this);
            },
        },
        created() {
            if (this.$route.query.t) {
                this.dateDrop.forEach((val) => {
                    if (val.key == parseInt(this.$route.query.t)) {
                        this.dateDropSelected = val;
                    }
                });
            } else {
                this.dateDropSelected = { key: 10000, value: "Overall" };
            }
            //this.calltraderDetail();
        },
    };
</script>
